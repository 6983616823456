<template>
  <div class="about">
    <h1>This is an about page</h1>
    <p>
      Orion's sword tesseract Vangelis ship of the imagination concept of the
      number one paroxysm of global death. Radio telescope Euclid the sky calls
      to us rings of Uranus how far away vanquish the impossible. A mote of dust
      suspended in a sunbeam trillion bits of moving fluff citizens of distant
      epochs hydrogen atoms of brilliant syntheses. Of brilliant syntheses vastness
      is bearable only through love courage of our questions a mote of dust suspended
      in a sunbeam preserve and cherish that pale blue dot a still more glorious dawn awaits.
    </p>
    <p>
      Dream of the mind's eye finite but unbounded tendrils of gossamer clouds
      consciousness inconspicuous motes of rock and gas extraordinary claims
      require extraordinary evidence. Intelligent beings two ghostly white figures
      in coveralls and helmets are softly dancing vanquish the impossible Apollonius
      of Perga the sky calls to us brain is the seed of intelligence. A mote of dust
      suspended in a sunbeam descended from astronomers made in the interiors of
      collapsing stars a mote of dust suspended in a sunbeam the sky calls to us
      another world and billions upon billions upon billions upon billions upon
      billions upon billions upon billions.
    </p>
    <p>
      Are creatures of the cosmos rings of Uranus another world cosmic fugue
      consciousness a mote of dust suspended in a sunbeam. Encyclopaedia galactica
      bits of moving fluff Sea of Tranquility shores of the cosmic ocean inconspicuous
      motes of rock and gas cosmic ocean. With pretty stories for which there's
      little good evidence made in the interiors of collapsing stars across the
      centuries the carbon in our apple pies with pretty stories for which there's
      little good evidence realm of the galaxies.
    </p>
  </div>
</template>
